// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$white: #fff !default;
$gray-100: #f3f4f6 !default;
$gray-200: #e5e7eb !default;
$gray-300: #d1d5db !default;
$gray-400: #9ca3af !default;
$gray-500: #6b7280 !default;
$gray-600: #4b5563 !default;
$gray-700: #374151 !default;
$gray-800: #1f2937 !default;
$gray-900: #111827 !default;
$black: #000 !default;

$blue: #3b82f6 !default;
$indigo: #6366f1 !default;
$purple: #8b5cf6 !default;
$pink: #ec4899 !default;
$red: #ef4444 !default;
$yellow: #f59e0b !default;
$green: #10b981 !default;

$primary: $indigo !default;
$secondary: $gray-600 !default;
$info: $blue !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

// $font-family-sans-serif: "Inter", sans-serif;
$font-family-sans-serif: "Poppins", sans-serif;
$font-size-base: 0.875rem;
// $font-size-base: 1rem;
$body-bg: $gray-100 !default;
$body-color: $gray-900 !default;

@import "~bootstrap/scss/bootstrap";

.line-through {
  text-decoration: line-through;
}

.grecaptcha-badge {
  visibility: hidden;
}

.modal-header {
  align-items: center;
}

.ql-editor {
  background-color: white;
}

.bg-black {
  background-color: #000;
}

.text-gray-100 {
  color: $gray-100;
}
.text-gray-200 {
  color: $gray-200;
}
.text-gray-300 {
  color: $gray-300;
}
.text-gray-400 {
  color: $gray-400;
}
.text-gray-500 {
  color: $gray-500;
}
.text-gray-600 {
  color: $gray-600;
}
.text-gray-700 {
  color: $gray-700;
}
.text-gray-800 {
  color: $gray-800;
}
.text-gray-900 {
  color: $gray-900;
}

.bg-gray-100 {
  background-color: $gray-100;
}
.bg-gray-200 {
  background-color: $gray-200;
}
.bg-gray-300 {
  background-color: $gray-300;
}
.bg-gray-400 {
  background-color: $gray-400;
}
.bg-gray-500 {
  background-color: $gray-500;
}
.bg-gray-600 {
  background-color: $gray-600;
}
.bg-gray-700 {
  background-color: $gray-700;
}
.bg-gray-800 {
  background-color: $gray-800;
}
.bg-gray-900 {
  background-color: $gray-900;
}

.font-thin {
  font-weight: 100;
}
.font-extra-light {
  font-weight: 200;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semi-bold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extra-bold {
  font-weight: 800;
}
.font-black {
  font-weight: 900;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.animate-spin {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebar {
  background-color: #111827;
  color: white;
  min-width: 250px;
  height: 100vh;
  max-width: 250px;
  overflow-y: auto;

  .sidebar-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e5e7eb;
    padding: 0.5rem 1rem;
    font-size: 20px;
    border-bottom: 1px solid #1f2937;
  }
  .sidebar-list {
    padding: 10px;
    ul {
      padding: 0;
      margin: 0;
    }
  }
}
.opacity-5 {
  opacity: 0.05;
}

.rdw-editor-main {
  height: 245px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  padding: 10px;
  box-sizing: border-box;
}
.rdw-editor-toolbar {
  padding: 5px !important;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin: 0px !important;
  user-select: none;
}
.public-DraftStyleDefault-block {
  margin: 0 !important;
}
.rdw-editor-wrapper:focus {
  outline: none;
}
.rdw-editor-wrapper {
  box-sizing: content-box;
}
.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}
.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: auto !important;
}
.rdw-option-wrapper {
  border: 1px solid #f1f1f1;
  padding: 10px !important;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
}
.rdw-option-wrapper:active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-option-active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-option-disabled {
  opacity: 0.3;
  cursor: default;
}

.slider-images {
  object-fit: contain;
}

@media (min-width: 576px) {
  .slider-images {
    height: 550px;
  }
}

.products-card-image {
  height: 400px !important;
  object-fit: cover !important;
  object-position: top;
}

.object-cover {
  object-fit: cover !important;
  object-position: top;
}

@include media-breakpoint-down(xs) {
  .products-card-image {
    height: auto !important;
  }
}

@include media-breakpoint-down(sm) {
  .products-card-image {
    height: auto !important;
  }
}

@include media-breakpoint-down(xl) {
  .logo {
    border-bottom: none !important;
  }
}

@include media-breakpoint-down(xs) {
  .logo {
    border-bottom: 1px solid #dee2e6 !important;
  }
}

.profile-menu {
  text-align: left;
  z-index: 10;
  display: flex;
  align-items: flex-start;

  ul {
    border-radius: 10px;
    overflow: hidden;
    flex-grow: 1;
    list-style-type: none;
    padding: 0;

    li {
      font-weight: 500 !important;
      border-bottom: 0.5px solid $gray-200;

      a {
        display: block;
        width: 100%;
        padding: 12px 15px;
        color: $dark;
        text-decoration: none;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: #4338ca !important;
          background-color: #eef2ff !important;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }

    li.profile {
      cursor: not-allowed;
      padding: 12px 15px;
      background-color: #f3f4f6 !important;
    }

    li.logout {
      cursor: pointer;
      padding: 12px 15px;
      color: #b91c1c;
      background-color: #fee2e2;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: #b91c1c !important;
        color: #fee2e2 !important;
      }
    }
  }
}

.btn-glass {
  color: $dark;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(160, 160, 160, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: none;
}

.in-stock {
  width: max-content;
  background-color: #a7f3d0;
  color: #065f46;
  padding: 0.25rem 1rem;
  font-size: 0.76563rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.out-of-stock {
  width: max-content;
  background-color: #fee2e2;
  color: #991b1b;
  padding: 0.25rem 1rem;
  font-size: 0.76563rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.active-profile-link {
  background-color: #eef2ff !important;
  color: #4338ca !important;
}

.radio-toolbar input[type="radio"] {
  display: none;
}

.radio-toolbar label {
  display: inline-block;
  background-color: #ddd;
  padding: 4px 11px;
  font-family: Arial;
  font-size: 16px;
  cursor: pointer;
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: #bbb;
}

.rich-editor-btn {
  background-color: transparent !important;
  height: 40px !important;
  width: 40px !important;
  border: 1px solid #d1d5db !important;
  border-radius: 0.25rem !important;

  &:hover {
    background-color: rgb(236, 236, 236) !important;
    box-shadow: none !important;
  }
}

.rich-editor-dropdown {
  background-color: transparent !important;
  border: 1px solid #d1d5db !important;
  border-radius: 0.25rem !important;
  height: 40px !important;
  width: 138px !important;

  a {
    font-size: 14px !important;
    color: $gray-900 !important ;
    text-decoration: none !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    .rdw-dropdown-carettoopen,
    .rdw-dropdown-carettoclose {
      position: unset;
    }
  }

  &:hover {
    background-color: rgb(236, 236, 236) !important;
    box-shadow: none !important;
  }
}

.demo-dropdown-custom {
  overflow: auto !important;
  background-color: white !important;
  width: 100% !important;
  border-bottom: 1px solid lightgrey !important;

  &:hover {
    box-shadow: none !important;
  }

  li {
    padding: 5px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid lightgrey;

    &:last-child {
      border: none;
    }
  }
}

.rdw-inline-wrapper,
.rdw-block-wrapper {
  margin: 0 !important;
}

.size-buttons-error {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
